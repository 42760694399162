import { Button, Collapse, Space, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import 'src/screens/passenger-information/styles/customerInformationPage.styles.less';
import { usePage } from 'src/components/page/Page';
import { useReservation } from 'src/providers/reservation-provider/ReservationContext';
import { INewPassengerParams } from 'src/types/passanger.type';
import { ReservationSummary } from 'src/components/reservation-summary/ReservationSummary';
import { PassengerForm } from 'src/components/passenger-form/PassengerForms';
import { useGetTripSeatMap } from 'src/hooks/GetTripSeats';
import { getSortedPassengers } from 'src/screens/passenger-information/passengerInfoUtil';
import { EditFilled } from '@ant-design/icons';
import { usePriceBookingnMutation } from 'src/graphql/mutations/price-booking';
import { CreatePassengersInput } from 'src/graphql/generated/types';

export const DROPDOWN_TEST_ID = 'DROPDOWN_TEST_ID';

const { Panel } = Collapse;

export const PassengerInformation: React.FC = () => {
  const { newPassengers, setNewPassengers, hasPassengerInformationComplete, schedule, setBookingPrice } =
    useReservation();

  const navigate = useNavigate();
  const { setSubTitle, setTitle } = usePage();
  const [{ data }] = useGetTripSeatMap(schedule!.tripId);
  const [priceBooking, { loading: priceBookingLoading }] = usePriceBookingnMutation();

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [activePanel, setActivePanel] = useState<string>();

  useEffect(() => {
    setTitle('E-ticketing');
    setSubTitle(`Customer details, ${activePanel}`);
  }, []);

  const selectedSeatIds =
    newPassengers
      ?.filter((passenger: INewPassengerParams) => passenger.seatId !== undefined)
      .map((passenger: INewPassengerParams) => passenger.seatId!) ?? [];

  const onFormComplete = (passenger: INewPassengerParams) => {
    const passengerIndex = newPassengers?.findIndex(
      (pass: INewPassengerParams) => pass.trackingId === passenger.trackingId,
    );
    if (passengerIndex !== undefined && passengerIndex !== -1 && newPassengers) {
      const passengerList = [...newPassengers];
      passengerList[passengerIndex] = passenger;
      setNewPassengers(passengerList);
    }
  };

  const getBookingPrice = async () => {
    const graphqlPassenger = newPassengers.map(({ trackingId, organizationId, tripId, ...rest }) => ({
      ...rest,
      // TODO: this is temporary, change this soon
      title: 'Mr',
      email: 'example@gmail.com',
    })) as CreatePassengersInput[];
    const bookingPrice = await priceBooking({
      variables: {
        input: {
          departure: {
            schedule: { tripId: schedule?.tripId ? String(schedule?.tripId) : '' },
            passengers: graphqlPassenger,
          },
        },
      },
    });

    if (bookingPrice.data) {
      setBookingPrice(bookingPrice.data?.priceBooking);
      navigate('/ticketing/payment-confirmation');
    }
  };

  const sortedPassengers = getSortedPassengers(newPassengers);
  const primaryPassenger = sortedPassengers.find((p) => p.isPrimaryPassenger);

  const passengerForms = sortedPassengers.map((passenger, index) => {
    let nextKey: string | undefined;

    if (index + 1 <= sortedPassengers.length - 1) {
      nextKey = sortedPassengers[index + 1].trackingId;
    }

    const prevKey = index > 0 ? sortedPassengers[index - 1].trackingId : sortedPassengers[0].trackingId;
    const isLast = index === sortedPassengers.length - 1;
    const isFirst = index === 0;

    return (
      <Panel
        header={`Passenger (${passenger.type})`}
        key={passenger.trackingId}
        extra={passenger.isPrimaryPassenger && <Tag color="#87d068">Primary Passenger</Tag>}
      >
        <PassengerForm
          type={passenger.type}
          passenger={passenger}
          isCheckinAvailable={schedule!.trip!.isCheckinAvailable as boolean}
          onComplete={(passanger) => {
            onFormComplete(passanger);
            if (isLast) {
              setCollapsed(true);
            } else {
              setActivePanel(nextKey);
            }
          }}
          primaryPassenger={primaryPassenger}
          selectedSeatIds={selectedSeatIds}
          seatMap={data}
          isFirst={isFirst}
          onGoBack={() => setActivePanel(prevKey)}
        />
      </Panel>
    );
  });

  const panel = activePanel ?? sortedPassengers[0].trackingId;

  return (
    <div className="container" data-testid={DROPDOWN_TEST_ID}>
      <ReservationSummary />
      <div className="collapse">
        <Typography.Title level={4}>Fill in passenger details</Typography.Title>
        <br />
        <Collapse activeKey={collapsed ? undefined : panel}>{passengerForms}</Collapse>
        <div className="proceed">
          <Space size={25}>
            {collapsed && (
              <Button
                type="dashed"
                size="large"
                icon={<EditFilled />}
                onClick={() => setCollapsed(false)}
                disabled={priceBookingLoading || !hasPassengerInformationComplete}
              >
                Edit
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              onClick={getBookingPrice}
              loading={priceBookingLoading}
              disabled={priceBookingLoading || !hasPassengerInformationComplete}
            >
              Proceed to payment
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
};
