import React, { useEffect, useState } from 'react';
import { Button, RadioChangeEvent, Radio, Typography, Row, Col, Popconfirm } from 'antd';
import moment from 'moment';

import 'src/screens/payment-confirmation/styles/paymentConfirmation.styles.less';
import { Cash, Pos, Transfer } from 'src/screens/payment-confirmation/icons/icons';
import { useReservation } from 'src/providers/reservation-provider/ReservationContext';
import { PASSENGERT_TYPE } from 'src/types/passanger.type';
import { useNavigate } from 'react-router-dom';
import { PAYMENT_METHODS } from 'src/types/transaction.type';
import { MoneyText } from 'src/components/topography/MoneyText';
import { usePage } from 'src/components/page/Page';
import { usePostCancelTicket } from 'src/hooks/usePostCancelTicket';
import { useMessage } from 'src/providers/message-provider/MessageProvider';
import { useLazyGetDestinationById } from 'src/hooks/getDestinationById';
import { LoadingView } from 'src/components/loading-view/LoadingView';
import { useCompleteReservation } from 'src/hooks/useCompleteReservation';
import { useCompleteWaybillReservation } from 'src/hooks/useCompleteWaybillReservation';
import { useCreateReservationnMutation } from 'src/graphql/mutations/create-or-edit-booking';

export const PAGE_TEST_ID = 'PAGE_TEST_ID';

export const PaymentConfirmation: React.FC = () => {
  const {
    newPassengers,
    transaction,
    schedule,
    passengers,
    getPassengerCount,
    setPassengers,
    setTickets,
    setTransaction,
    reservationType,
    waybill,
    setWaybill,
    bookingPrice,
    reservation,
    setReservation,
  } = useReservation();
  const navigate = useNavigate();

  const [fetch, { data: destinationData, loading: destinationLoading }] = useLazyGetDestinationById();
  const [completeReservation, { loading: completeReservationLoading }] = useCompleteReservation();
  // const [createReservation, { loading: createReservationLoading }] = useCreateReservation();
  const [completeWaybillReservation, { loading: completeWaybillReservationLoading }] = useCompleteWaybillReservation();
  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHODS>();
  const { setSubTitle, setTitle } = usePage();
  const [cancelTicket, { loading: cancelTicketLoading }] = usePostCancelTicket();
  const [createReservation, { loading: createReservationLoading, data: createReservationData }] =
    useCreateReservationnMutation();
  const { successMessage, errorMessage } = useMessage();

  useEffect(() => {
    const booking = createReservationData?.createOrEditReservation;
    if (booking) {
      setTransaction(booking.transaction);
      setTickets(booking.tickets);
      setPassengers(booking.passengers);
      setReservation(booking.reservation);
    }
  }, [createReservationData]);

  const createReservationTicket = async () => {
    if (!schedule?.tripId) return;
    const passengersWithooutTracking = newPassengers.map(({ trackingId, tripId, organizationId, ...pass }) => pass);
    await createReservation({
      variables: {
        input: {
          departure: { schedule: { tripId: String(schedule?.tripId) }, passengers: passengersWithooutTracking },
        },
      },
    });
  };

  const onChange = (e: RadioChangeEvent) => {
    setPaymentMethod(e.target.value);
    if (!transaction) {
      createReservationTicket();
    }
  };

  useEffect(() => {
    setTitle('E-ticketing');
    setSubTitle('Payment method');
  }, []);

  useEffect(() => {
    if (reservationType === 'Waybill') {
      fetch({ params: { id: waybill?.destinationId as number } });
    }
  }, [reservationType]);

  const issueTickets = async () => {
    if (!reservation) return;
    const booking = await completeReservation({
      params: {
        reservationId: Number(reservation.id),
        paymentMethod: paymentMethod!, // for this function to be called a payment method would have been selected
      },
    });
    if (booking) {
      setTransaction({
        id: String(booking.transaction.id),
        amount: booking.transaction.amount,
        totalAmount: booking.transaction.amount + booking.transaction.serviceCharge,
        serviceCharge: booking.transaction.serviceCharge,
        paymentMethod: booking.transaction.paymentMethod,
      });
      navigate('/ticketing/reservation-confirmation');
    }
  };

  const issueWaybillTicket = async () => {
    if (!transaction?.id) return;
    const result = await completeWaybillReservation({
      params: {
        waybillId: waybill?.id!,
        transactionId: Number(transaction.id),
        paymentMethod: paymentMethod!,
      },
    });
    if (result) {
      setWaybill(result.waybill);
      setTransaction({
        id: String(result.transaction.id),
        amount: result.transaction.amount,
        totalAmount: result.transaction.amount + result.transaction.serviceCharge,
        serviceCharge: result.transaction.serviceCharge,
        paymentMethod: result.transaction.paymentMethod,
      });
      navigate('/ticketing/reservation-confirmation');
    }
  };

  const onCancelReservation = async () => {
    const passangerIds = passengers.map((p) => p.id);
    if (passangerIds) {
      const cancelList = passangerIds.map((id) => cancelTicket(Number(id)));
      try {
        await Promise.all(cancelList);
        successMessage({ content: 'Reservation cancelled' });
      } catch (e) {
        errorMessage({ content: 'Error cancelling reservation. Try again' });
      }
      window.location.href = '/ticketing';
    }
  };

  const departureDate = moment(`${schedule?.trip?.date} ${schedule?.trip?.time}`).format('ddd Do MMM, YYYY');
  const adultCount = bookingPrice?.departure.passengers.filter(
    (passenger) => passenger.type === PASSENGERT_TYPE.ADULT,
  ).length;
  const childCount = bookingPrice?.departure.passengers.filter(
    (passenger) => passenger.type === PASSENGERT_TYPE.CHILD,
  ).length;
  const infantCount = bookingPrice?.departure.passengers.filter(
    (passenger) => passenger.type === PASSENGERT_TYPE.INFANT,
  ).length;

  const loading = completeReservationLoading || createReservationLoading || completeWaybillReservationLoading;
  if (reservationType === 'Waybill' && destinationLoading) return <LoadingView />;

  return (
    <div>
      <div className="payment-confirm-container" data-testid={PAGE_TEST_ID}>
        <div className="left">
          <div className="left-header">
            <Typography.Title level={5}>Select payment method</Typography.Title>
          </div>
          <div className="options-container">
            <div className="options">
              <div className="payment-option">
                <Cash />
                Cash
              </div>
              <div className="payment-option">
                <Transfer />
                Online Bank Transfer
              </div>
              <div className="payment-option">
                <Pos />
                POS Terminal
              </div>
            </div>
            <Radio.Group onChange={onChange} value={paymentMethod} className="radios">
              <Radio value={PAYMENT_METHODS.CASH} className="radio radio-cash" />
              <Radio value={PAYMENT_METHODS.BANK_TRANSFER} className="radio radio-transfer" />
              <Radio value={PAYMENT_METHODS.POS} className="radio radio-pos" />
            </Radio.Group>
          </div>
        </div>
        <div className="right">
          <div className="right-header">
            <Typography.Title level={5}>Ticket Summary</Typography.Title>
          </div>
          {reservationType === 'Passenger' && (
            <>
              <div className="options-container">
                <div className="options">
                  <div className="option">Departure Date</div>
                  <div className="value">{departureDate}</div>
                </div>
                <div className="options">
                  <div className="option">Full Name</div>
                  <div className="value">{bookingPrice?.departure.passengers[0].fullName}</div>
                </div>
                <div className="options">
                  <div className="option">Departure Terminal</div>
                  <div className="value">{schedule?.route?.branch?.name}</div>
                </div>
                <div className="options">
                  <div className="option">Destination Terminal</div>
                  <div className="value">
                    {schedule?.route?.destination?.name}({schedule?.route?.destination?.code})
                  </div>
                </div>
                <div className="options">
                  <div className="option">Total Passenger</div>
                  <div className="value">
                    <div>
                      <p>({getPassengerCount()}X) Passenger(s)</p>
                      <p>
                        {adultCount} Adult <br />
                        {childCount} Child <br />
                        {infantCount} Infant
                      </p>
                    </div>
                  </div>
                </div>
                <div className="options">
                  <div className="option">Actual Fare Amount</div>
                  <div className="value">
                    <div>
                      <span>Actual fare:</span> <MoneyText value={bookingPrice?.departure.actualFare as number} />
                    </div>
                  </div>
                </div>
                <div className="options">
                  <div className="option">Discount</div>
                  <div className="value">
                    <div>
                      <span>Discount :</span>{' '}
                      <MoneyText
                        value={
                          ((bookingPrice?.departure.actualFare as number) -
                            (bookingPrice?.departure.totalFare ?? 0)) as number
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-bottom-header">
                <Typography.Title level={5}>Total Amount</Typography.Title>
                <Typography.Title level={5}>
                  <MoneyText value={bookingPrice?.departure.totalFare as number} />
                </Typography.Title>
              </div>
              <Row gutter={24}>
                <Col span={12}>
                  <Button
                    typeof="submit"
                    type="primary"
                    size="large"
                    className="issue"
                    onClick={issueTickets}
                    disabled={!reservation || loading}
                    loading={loading}
                  >
                    ISSUE TICKET
                  </Button>
                </Col>
                <Col span={12}>
                  <Popconfirm
                    title="Are you sure to cancel this reservation?"
                    onConfirm={() => onCancelReservation()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      danger
                      size="large"
                      className="issue"
                      disabled={loading || cancelTicketLoading || !reservation}
                      loading={loading}
                    >
                      CANCEL RESERVATION
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </>
          )}

          {reservationType === 'Waybill' && (
            <>
              <div className="options-container">
                <div className="options">
                  <div className="option">Departure Date</div>
                  <div className="value">{moment(waybill?.date).format('ddd, Do MMM YYYY')}</div>
                </div>
                <div className="options">
                  <div className="option">Sender Name</div>
                  <div className="value">{waybill?.sendersName}</div>
                </div>
                <div className="options">
                  <div className="option">Destination</div>
                  <div className="value">
                    {destinationData?.name} ({destinationData?.code})
                  </div>
                </div>
              </div>
              <div className="right-bottom-header">
                <Typography.Title level={5}>Waybill Amount</Typography.Title>
                <Typography.Title level={5}>
                  <MoneyText value={(transaction?.amount ?? 0) + (transaction?.serviceCharge ?? 0)} />
                </Typography.Title>
              </div>
              <Button
                typeof="submit"
                type="primary"
                size="large"
                className="issue"
                onClick={waybill && issueWaybillTicket}
                disabled={!paymentMethod || loading}
                loading={loading}
              >
                ISSUE WAYBILL TICKET
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
